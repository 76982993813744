import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  content,
  descriptionContent,
  categoryContainer,
  categoryContent,
  categoryIcon,
  categoryTitle,
  categorySubTitle,
  contactContainer,
  contactTitle,
  contactSubTitle,
} from "../components/layout.module.css";
import { Link } from "gatsby";

const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <SEO title={"Home"} />
      <div className="row">
        <div className="col-8" style={{ marginTop: 23 }}>
          <div className={content}>
            <p>Discover</p>
            <p>New Possibilities</p>
          </div>
        </div>
        <div className="col-4" style={{ marginTop: -72 }}>
          <StaticImage
            src="../images/illustration.png"
            alt="Illustration"
            width={372}
            placeholder="none"
          />
        </div>
      </div>
      <div className="row">
        <div className={`${descriptionContent} col`}>
          <p>Apps for every day use </p>
          <p>across 4 categories</p>
        </div>
      </div>
      <div className="row" style={{ marginTop: 51 }}>
        <div className="col-xl-3 col-md-6 col-sm-12">
          <div className={categoryContainer}>
            <div className={categoryContent}>
              <p className={categoryIcon}>💼</p>
              <p className={categoryTitle}>Business</p>
              <p className={categorySubTitle}>Solutions of SMEs</p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-12">
          <div className={categoryContainer}>
            <div className={categoryContent}>
              <p className={categoryIcon}>⛱</p>
              <p className={categoryTitle}>Lifestyle</p>
              <p className={categorySubTitle}>Improve quality life</p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-12">
          <div className={categoryContainer}>
            <div className={categoryContent}>
              <p className={categoryIcon}>🚀</p>
              <p className={categoryTitle}>Productivity</p>
              <p className={categorySubTitle}>
                Make your work efficient and effective
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-12">
          <div className={categoryContainer}>
            <div className={categoryContent}>
              <p className={categoryIcon}>🛠</p>
              <p className={categoryTitle}>Utility</p>
              <p className={categorySubTitle}>Do more on your phone</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className={`col ${contactContainer}`}>
          <p className={contactTitle}>Contact us</p>
          <p>
            <Link
              className={contactSubTitle}
              href="mailto:info@dmusoftware.com"
            >
              info@dmusoftware.com
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
